<template>
    <div class="c-app flex-row align-items-center">
        <CContainer>
            <CRow class="justify-content-center">
                <CCol md="8">
                    <CCardGroup>
                        <CCard class="p-4">
                            <CCardBody>
                                <CForm @keyup.enter="validator();sign_in()">
                                    <h1>Login</h1>
                                    <p class="text-muted">Sign In to your account</p>
                                    <CInput v-model="form.username.val" label="E-mail" />
                                    <CInput v-model="form.password.val" label="Password" type="password" />
                                    <CRow>
                                        <CCol sm="6" class="text-left">
                                            <CButton @click="sign_in" color="primary" class="px-4">Login</CButton>
                                        </CCol>
                                        <CCol sm="6" class="text-right">
                                            <CButton @click="redirect_to('/password/reset')" color="link" class="px-0">Forgot password?</CButton>
                                            <CButton @click="clear_localstorage" color="link" class="px-0">Clear Local Storage</CButton>
                                            <CButton @click="redirect_to('/signup')" color="link" class="d-lg-none">Register now!</CButton>
                                        </CCol>
                                    </CRow>
                                </CForm>
                            </CCardBody>
                        </CCard>
                        <CCard color="primary" text-color="white" class="text-center py-5 d-md-down-none" body-wrapper>
                            <CCardBody>
                                <h2>Sign up</h2>
                                <p>Create your own account by clicking on Register button in order to use our services.</p>
                                <CButton @click="redirect_to('/signup')" color="light" variant="outline" size="lg">
                                    Register Now!
                                </CButton>
                            </CCardBody>
                        </CCard>
                    </CCardGroup>
                </CCol>
            </CRow>
        </CContainer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                username: {val: '', inv: '', v: { req: true, max: 1024, spec: 'email' }},
                password: {val: '', inv: '', v: { req: true }},
            },
            disable: false,
            perms:{
                'arbitrage.DataSourceLogs': {delete: false, get: false, post: false, put: false},
                'arbitrage.DataSources': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputDatas': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputProccess': {delete: false, get: false, post: false, put: false},
                'arbitrage.InputProccessTriggers': {delete: false, get: false, post: false, put: false},
                'arbitrage.OutputDatas': {delete: false, get: false, post: false, put: false},
                'arbitrage.SystemCustomValues': {delete: false, get: false, post: false, put: false},
                'system.GuestUserTokens': {delete: false, get: false, post: false, put: false},
                'system.TelegramAccounts': {delete: false, get: false, post: false, put: false},
                'system.User': {delete: false, get: false, post: false, put: false},
                'system.UserGroup': {delete: false, get: false, post: false, put: false},
                'system.UsersToUserGroup': {delete: false, get: false, post: false, put: false},
            },
        }
    },
    methods: {
        validator() {
            for (const key in this.form){
                this.form[key].inv = '';
            }
            this.disable = false;
            for (const key in this.form){
                for (const k in this.form[key].v){
                    switch(k){
                        case 'req':
                            if(this.form[key].val == ''){
                                this.form[key].inv = 'This field is required.';
                                this.disable = true;
                            }
                            break;
                        case 'max':
                            if(this.form[key].val != ''){
                                if(this.form[key].val.length > this.form[key].v[k]){
                                    this.form[key].inv = `Must be a maximum of ${this.form[key].v[k]} characters.`;
                                    this.disable = true;
                                }
                            }
                            break;
                        case 'spec':
                            switch(this.form[key].v[k]){
                                case 'email':
                                    if (this.form[key].val != '' && this.form[key].inv == '') {
                                        const re_email = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                                        if (!re_email.test(String(this.form[key].val).toLowerCase())) {
                                            this.form[key].inv = 'E-mail is not valid.';
                                            this.disable = true;
                                        }
                                    }
                                    break;
                            }
                            break;
                    }
                }
            }
        },
        sign_in() {
            if(this.disable == false){
                const data = new FormData();
                for (const key in this.form) {
                    data.set(key, this.form[key].val);
                }
                this.axios.post('signin', data)
                    .then(res => {
                        this.$notify({
                            group: 'foo',
                            type: 'success',
                            text: `${res.data.user_info.first_name} ${res.data.user_info.last_name} welcome.`
                        });
                        let d = new Date();
                        d.setDate(d.getDate() + 1);
                        this.$store.commit('set_expires', d);
                        this.$store.commit('set_token', `Bearer ${res.data.token}`);
                        for(const x in res.data.user_info.user_perm_array){
                            for(const y in this.perms){
                                if(x == y){
                                    for(const i in res.data.user_info.user_perm_array[x]){
                                        for(const j in this.perms[y]){
                                            if(i == j){
                                                this.perms[y][j] = res.data.user_info.user_perm_array[x][i];
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.$store.commit('set_perm', this.perms);
                        this.$store.commit('set_user_id', res.data.user_info.id);
                        const styles = localStorage.getItem('styles');
                        if(!styles){
                            localStorage.setItem('styles', JSON.stringify([]));
                        }
                        if (this.$route.query.redirect == undefined) {
                            this.$router.push(`/dashboard/sort_num/asc`);
                        } else {
                            this.$router.push(this.$route.query.redirect);
                        }
                    })
                    .catch(err => {
                        if (err.response.status == 401) {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                text: 'Incorrect username or password',
                            });
                        } else {
                            this.$notify({
                                group: 'foo',
                                type: 'error',
                                text: 'Error! please try again.'
                            });
                        }
                    });
            }
        },
        redirect_to(route) {
            this.$router.push(route);
        },
        clear_localstorage(){
            localStorage.clear();
            this.$notify({
                group: 'foo',
                type: 'success',
                text: 'Done!'
            });
        }
    }
}
</script>
